.rainbow-border {
    animation: rainbow 3s infinite;
  }
  
  @keyframes rainbow {
    0% {border-color: rgb(255, 46, 46);}
    20% {border-color: rgb(195, 195, 0);}
    40% {border-color: lime;}
    60% {border-color: aqua;}
    80% {border-color: rgb(45, 45, 255);}
    100% {border-color: magenta;}
  }