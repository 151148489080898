.fullheight {
    height: 100%;
}

.small.MuiButton-root {
    font-size: 0.600rem;
}

.zoommed-out {
    width: 125%;
    height: 125%;
    transform-origin: top left;
    transform: scale(80%);
}





/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: 10px;
    scrollbar-color: #bfbfbf #e0e0e0;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 0px solid #ffffff;
  }