.phoneCallIcon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
}

.out_answered {
    background-image: url(https://dashboard.ringover.com/img/callOUT.svg);
    color: #1d91ed;
}

.in_answered {
    background-image: url(https://dashboard.ringover.com/img/callINOK.svg);
    color: #3cc8c8;
}

.missed {
    background-image: url(https://dashboard.ringover.com/img/callINKO.svg);
    color: #f2587a;
}

.closed {
    background-image: url(https://dashboard.ringover.com/img/callOUTPLANNING_VOICEMAIL.svg);
    color: #f5a623;
}

.voicemail {
    background-image: url(https://dashboard.ringover.com/img/callVOICEMAIL.svg);
    color: #f5a623;
}

.out_cancelled {
    background-image: url(https://dashboard.ringover.com/img/callOUT.svg);
    color: #1d91ed;
    filter: grayscale(100%);
}

.out_failed {
    background-image: url(https://dashboard.ringover.com/img/callOUT.svg);
    color: #1d91ed;
    filter: hue-rotate(95degs);
}
